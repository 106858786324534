import * as React from "react";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import IconButton from "@mui/material/IconButton";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwittwerIcon from "@mui/icons-material/Twitter";
import MailIcon from "@mui/icons-material/Mail";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import TelegramIcon from "@mui/icons-material/Telegram";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import { FaLinkedin, FaTiktok } from "react-icons/fa";
import { connect } from "react-redux";
import { logout } from "../store/actions/authActions";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Avatar, Button, Drawer, Grid, Link, Tooltip } from "@mui/material";
import { centered_flex_box, left_flex_box, right_flex_box } from "./Styles";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";
import { UserLogin } from "../../Pages";
import StarsIcon from "@mui/icons-material/Stars";
import YouTube from "@mui/icons-material/YouTube";
import Logo from "../components/logoMinimal.png";
import { Image } from "antd";
import { Mail, Phone } from "@mui/icons-material";

function Footer({ auth }) {
  const navigate = useNavigate();

  const token = auth.token || "";
  const header = token.split(" ");
  const role = header[0];
  const location = useLocation();

  const route = (path) => {
    navigate(path);
  };

  if (isMobile) {
    return (
      <Box paddingTop={5} paddingX={5}>
        <hr
          style={{
            borderTop: "solid 1px var(--secColor4)",
            opacity: "50%",
            marginBottom: 10,
          }}
        />
        <Grid container spacing={2}>
          <Grid item xs={12} sx={{ ...centered_flex_box }}>
            <Image src={Logo} preview={false} width={240} />
          </Grid>
          <Grid item container spacing={2} xs={12} sx={{ ...centered_flex_box }}>
            <Grid item xs={12} sx={{ ...centered_flex_box }}>
              <Typography fontSize={16}><Phone /> <a href={"tel:+201226009114"} target="_blank" rel="noreferrer">+201226009114</a></Typography>
            </Grid>
            <Grid item xs={12} sx={{ ...centered_flex_box }}>
              <Typography fontSize={16}><Mail /> <a href={"mailto:customer.support@chainzsolutions.com"} target="_blank" rel="noreferrer">Email</a></Typography>
            </Grid>
            <Grid item xs={12} sx={{ ...centered_flex_box }}>
              <Typography fontSize={16}><LocationOnIcon /> Warehouse (E11/4), Industrial Area, New Cairo, Cairo </Typography>
            </Grid>
          </Grid>
        </Grid>
        <Box sx={{ ...centered_flex_box, flexDirection:"column", mt: 5 }}>
          <NavLink to={"/"}><Typography mb={2} fontWeight={500} fontSize={16} marginX={2}>Home</Typography></NavLink>
          <NavLink to={"/services"}><Typography mb={2} fontWeight={500} fontSize={16} marginX={2}>Services</Typography></NavLink>
          <NavLink to={"/about"}><Typography mb={2} fontWeight={500} fontSize={16} marginX={2}>About</Typography></NavLink>
        </Box>
        <hr
          style={{
            borderTop: "solid 1px var(--secColor4)",
            opacity: "10%",
            marginBottom: 10,
          }}
        />
        <Grid item xs={12} my={3} sx={{ ...centered_flex_box }}>
          <IconButton sx={{ mx: 1 }}><InstagramIcon /></IconButton>
          <IconButton sx={{ mx: 1 }}><FacebookIcon /></IconButton>
          <IconButton sx={{ mx: 1 }}><FaTiktok /></IconButton>
        </Grid>
        <Typography textAlign={"center"} fontSize={16}><Link href={"/terms"}>Terms & Uses</Link></Typography>
        <Typography textAlign={"center"} fontSize={16} marginTop={2}>© 2025 Chainz. All Rights Reserved.</Typography>
        <hr
          style={{
            borderTop: "solid 1px var(--secColor4)",
            opacity: "10%",
          }}
        />
        <Typography textAlign={"center"} fontSize={16}><a href={"mailto:tech@integraegy.com"} target="_blank" rel="noreferrer">Developed by Integra</a></Typography>
      </Box>
    )
  }

  return (
    <Box paddingTop={5} paddingX={5}>
      <hr
        style={{
          borderTop: "solid 1px var(--secColor4)",
          opacity: "50%",
          marginBottom: 10,
        }}
      />
      <Grid container>
        <Grid item xs={3} sx={{ ...left_flex_box }}>
          <Image src={Logo} preview={false} width={240} />
        </Grid>
        <Grid item container xs={6} sx={{ ...centered_flex_box }}>
          <Grid item xs={4} sx={{ ...centered_flex_box }}>
            <Typography fontSize={16}><Phone /> <a href={"tel:+201226009114"} target="_blank" rel="noreferrer">+201226009114</a></Typography>
          </Grid>
          <Grid item xs={4} sx={{ ...centered_flex_box }}>
            <Typography fontSize={16}><Mail /> <a href={"mailto:customer.support@chainzsolutions.com"} target="_blank" rel="noreferrer">Email</a></Typography>
          </Grid>
          <Grid item xs={4} sx={{ ...centered_flex_box }}>
            <Typography fontSize={16}><LocationOnIcon /> Warehouse (E11/4), Industrial Area, New Cairo, Cairo </Typography>
          </Grid>
        </Grid>
        <Grid item xs={3} sx={{ ...right_flex_box }}>
          <IconButton sx={{ mx: 1 }}><InstagramIcon /></IconButton>
          <IconButton sx={{ mx: 1 }}><FacebookIcon /></IconButton>
          <IconButton sx={{ mx: 1 }}><FaTiktok /></IconButton>
        </Grid>
      </Grid>
      <Box sx={{ ...centered_flex_box, mt: 10 }}>
        <NavLink to={"/"}><Typography fontWeight={500} fontSize={16} marginX={2}>Home</Typography></NavLink>
        <NavLink to={"/services"}><Typography fontWeight={500} fontSize={16} marginX={2}>Services</Typography></NavLink>
        <NavLink to={"/about"}><Typography fontWeight={500} fontSize={16} marginX={2}>About</Typography></NavLink>
      </Box>
      <hr
        style={{
          borderTop: "solid 1px var(--secColor4)",
          opacity: "10%",
          marginBottom: 10,
        }}
      />
      <Typography textAlign={"center"} fontSize={16}><Link href={"/terms"}>Terms & Uses</Link></Typography>
      <Typography textAlign={"center"} fontSize={16} marginTop={2}>© 2025 Chainz. All Rights Reserved.</Typography>
      <hr
        style={{
          borderTop: "solid 1px var(--secColor4)",
          opacity: "10%",
        }}
      />
      <Typography textAlign={"center"} fontSize={16}><a href={"mailto:tech@integraegy.com"} target="_blank" rel="noreferrer">Developed by Integra</a></Typography>
    </Box>
  );
}

const mapStateToProps = (state) => ({
  auth: state?.auth,
});

const mapDispatchToProps = { logout };

export default connect(mapStateToProps, mapDispatchToProps)(Footer);
